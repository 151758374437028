<template>
  <b-container class="container-rda siderbar" style="max-width: 100%">
    <b-row class="height">
      <sidebar-admin />
      <b-col class="content">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h2 class="purple bold">Informe Periódico</h2>
          </b-col>
          <b-col class="text-right">
            <b-button class="btn outline blue" @click="openPeriodModal('Novo')">
              Novo período</b-button>
          </b-col>
        </b-row>
        <b-row style="margin-top: 20px">
          <b-col>
            <b-container fluid>
              <b-row align-v="center" class="list-teacher" v-for="(period, index) in bimontlyPeriods"
                v-bind:key="period.id">
                <b-col md="4">
                  <b-row>
                    <b-col>
                      <p>
                        {{ period.name }} <b>{{ period.active ? " - Ativo ✅" : "" }}</b>
                      </p>

                      <b>

                      </b>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="3">
                  <b-row>
                    <b-col md="0" class="label-date">
                      <label>Data Inicial</label>
                    </b-col>
                    <b-col>
                      <Datepicker class="calendar" disabled v-model="period.from_date" placeholder="Selecione"
                        format="dd/MM/yyyy" :use-utc="true" :language="languages[language]"
                        @selected="fixDate('from_date', index)">
                      </Datepicker>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="3">
                  <b-row>
                    <b-col md="0" class="label-date">
                      <label>Data Final</label>
                    </b-col>
                    <b-col>
                      <Datepicker class="calendar" disabled v-model="period.to_date" placeholder="Selecione"
                        format="dd/MM/yyyy" :use-utc="true" :language="languages[language]"
                        @selected="fixDate('to_date', index)">
                      </Datepicker>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col class="text-right">
                  <a v-on:click.prevent="edit(period)">
                    <EditIcon />
                  </a>
                </b-col>

                <b-col class="text-right">
                  <a v-on:click.prevent="exclude(index)">
                    <DeleteIcon />
                  </a>
                </b-col>
              </b-row>

              <b-row align-v="center" class="list-teacher" v-if="bimontlyPeriods.length <= 0 && requestSent">
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>

              <b-row align-v="center" class="list-teacher" v-if="!requestSent">
                <b-col>Carregando</b-col>
              </b-row>

              <b-row class="align-center" v-else-if="count > perPage && requestSent">
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal id="modal-period" hide-footer>
      <template #modal-title>{{ modalMode }} Registro</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-group id="modal-form-observation" label="Name" label-for="form-observation">
            <b-input id="form-observation" v-model="periodModalData.name" placeholder="Nome do período"></b-input>
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group id="modal-form-from-date" label="Data Inicial" label-for="form-from-date">
                <Datepicker class="calendar" v-model="periodModalData.from_date" placeholder="Selecione"
                  format="dd/MM/yyyy" :use-utc="true" :language="languages[language]">
                </Datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="modal-form-to-date" label="Data Final" label-for="form-to-date">
                <Datepicker class="calendar" v-model="periodModalData.to_date" placeholder="Selecione"
                  format="dd/MM/yyyy" :use-utc="true" :language="languages[language]">
                </Datepicker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group>
                <b-form-checkbox v-model="periodModalData.active" name="active" value="true" unchecked-value="false">
                  Ativo
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col>
              <b-button class="btn large outline" @click="hidePeriodModal()">Voltar
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button class="btn large blue" @click="submitPeriod()">
                {{ modalMode === "Novo" ? "Criar" : "Atualizar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda";
import SidebarAdmin from "../../../components/SidebarAdmin.vue";
import moment from "moment-timezone";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";

export default {
  components: {
    SidebarAdmin,
    Datepicker,
    DeleteIcon,
    EditIcon,
  },

  data() {
    return {
      name: "reports",
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      modalMode: "Novo",
      periodModalData: {
        name: "",
        from_date: "",
        to_date: "",
      },
      breadcrumb: [
        {
          text: "Administração",
          href: "/administracao",
        },
        {
          text: "Informe Periódico",
          href: "/administracao/importar",
        },
      ],
      requestSent: false,
      bimontlyPeriods: [],
      count: 0,
      page: 1,
      perPage: 20,
      language: "ptBR",
      languages: lang,
    };
  },

  methods: {
    hidePeriodModal() {
      this.$bvModal.hide("modal-period");
      this.modalMode = null;
    },

    openPeriodModal(mode) {
      this.modalMode = mode;

      this.periodModalData = {
        id: null,
        name: "",
        from_date: "",
        to_date: "",
      };

      this.$bvModal.show("modal-period");
    },

    submitPeriod() {
      let someEmpty = false;

      Object.keys(this.periodModalData).forEach((key) => {
        if (this.periodModalData[key] === "") {
          someEmpty = true;
          this.$bvToast.toast("Preencha todos os campos", {
            title: "Erro",
            variant: "danger",
          });
          return;
        }
      });

      if (someEmpty) return;

      if (this.modalMode === "Novo") {
        this.createPeriod();
      } else {
        this.updatePeriod();
      }
      this.hidePeriodModal();
    },

    edit(period) {
      this.openPeriodModal("Editar");

      this.periodModalData = {
        id: period.id,
        name: period.name,
        from_date: period.from_date,
        to_date: period.to_date,
        active: period.active,
      };
    },

    createPeriod() {
      const payload = {
        name: this.periodModalData.name,
        from_date: this.periodModalData.from_date,
        to_date: this.periodModalData.to_date,
      };

      if (typeof this.periodModalData.active === "string") {
        payload.active = this.periodModalData.active === "true";
      }

      httpRda
        .post("/periods", payload)
        .then(() => {
          this.getBimontlyPeriods();
        })
        .catch(() => {
          this.$bvToast.toast("Erro ao criar período", {
            title: "Erro",
            variant: "danger",
          });
        });

      this.hidePeriodModal();
    },

    updatePeriod() {
      const payload = {
        name: this.periodModalData.name,
        from_date: this.periodModalData.from_date,
        to_date: this.periodModalData.to_date,
      };

      if (typeof this.periodModalData.active === "string") {
        payload.active = this.periodModalData.active === "true";
      }

      httpRda
        .patch(`/periods/${this.periodModalData.id}`, payload)
        .then(() => {
          this.getBimontlyPeriods();
        })
        .catch(() => {
          this.$bvToast.toast("Erro ao atualizar período", {
            title: "Erro",
            variant: "danger",
          });
        });

      this.hidePeriodModal();
    },

    exclude(index) {
      const element = this.bimontlyPeriods[index];

      if (!element.id) {
        this.bimontlyPeriods.splice(index, 1);
      } else {
        this.$bvModal
          .msgBoxConfirm("Deseja realmente excluir este período?")
          .then(async (value) => {
            if (value) {
              try {
                await httpRda.delete(`/periods/${element.id}`);
                this.getBimontlyPeriods();
              } catch (error) {
                this.$bvToast.toast(error.response?.data?.error || "Erro ao excluir período", {
                  title: "Erro",
                  variant: "danger",
                });
              }
            }
          });
      }
    },

    changePage(page) {
      this.page = page;
      this.getBimontlyPeriods();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    async getBimontlyPeriods() {
      this.requestSent = false;
      this.bimontlyPeriods = [];

      try {
        const response = await httpRda.get("/periods", {
          params: {
            page: this.page,
            limit: this.perPage,
            q: this.search,
          },
        });

        const result = response.data.result;

        this.requestSent = true;

        this.bimontlyPeriods = result.count > 0 ? result.rows : [];

        this.count = result.count;
        this.page = result.page;

        this.requestSent = true;
      } catch (error) {
        this.requestSent = true;
        this.bimontlyPeriods = [];
      }
    },

    fixDate(propName, index) {
      const currentTzOffset = Math.abs(moment().utcOffset() / 60);
      this.bimontlyPeriods[index][propName] = moment(
        this.bimontlyPeriods[index][propName]
      )
        .add(currentTzOffset > 0 ? 1 : 0, "days")
        .format("YYYY-MM-DD")
        .toString();
      this.page = 1;

      this.getBimontlyPeriods();
    },
  },

  mounted() {
    this.getBimontlyPeriods();
  },
};
</script>

<style>
.outline {
  color: #68c5b7 !important;
  background: transparent;

  &:hover {
    color: #fff !important;
  }
}

.label-date {
  margin-top: 15px !important;
}
</style>
